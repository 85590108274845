<template>
    <div class="w-100">
        <h5>КОМПЛЕКС  H-HA/L-HA з гіалуронової кислоти двох молекулярних мас</h5>
        <p>Одночасна присутність в одному препараті ГК різних молекулярних мас - високої та низької - дозволяє інтегрувати і збалансувати  рівні ендогенної ГК.</p>
        <strong>ГК низької молекулярної маси 110 kDA:</strong>
        <p>стимулює проліферацію фібробластів та кератиноцитів, покращує мікроциркуляцію, живлення та зволоження шкіри. Має протизапальну дію</p>
        <strong>ГК високої молекулярної маси 1200 kDA:</strong>
        <p>сприяє глибокому та тривалому зволоженню шкіри, реструктурує її</p>
        <h5>АМІНОКИСЛОТНИЙ КЛАСТЕР</h5>
        <strong>Гліцин - Пролін - Аргінін</strong>
        <p>Амінокислоти мають ізометричну конфігурацію - L-форму, що робить їх максимально біодоступними для організму.
        </p>
        <p>Гліцин та пролін: основні амінокислоти, що стимулюють синтез колагену та формують його просторову структуру.
        </p>
        <p>Аргінін: є одним із ключових метаболітів у процесах азотистого обміну організму, відіграє важливу роль у вазодилатації.</p>
        <h5></h5>
        <p></p>
    </div>
</template>