<template>
  <div class="row product-bg-1 mt-lg-5 pt-lg-5">
    <div class="col-12 product-bg-1-2 px-primary">
      <div class="row">
        <div class="col-12 d-flex flex-column">
          <div class="d-flex align-items-start justify-content-start flex-column flex-lg-row">
            <h1 class="title-home mt-0 mb-2">AGE REVERSE</h1>
            <div class="slogan  ms-3 text-nowrap">
              <span v-for="(charObj, index) in currentSlogan" :key="index" :style="{ opacity: charObj.opacity }">
                {{ charObj.char }}
              </span>
            </div>
          </div>


          <div class="mt-2 col-7 col-lg-5">Лабораторії Love Cosmedical Nano Technology Laboratory в Італії, власники
            брендів Love
            Cosmedical та Marc Medical, спеціалізуються на розробці та виробництві препаратів для естетичної медицини,
            поєднуючи новітні технології, науковий досвід і креатив.</div>
          <div class="d-flex mt-4 mb-5 mb-lg-0">
            <button type="button" class="btn  rounded-pill second-bg d-flex align-items-center px-2"
              data-bs-toggle="modal" data-bs-target="#exampleModal"
              @click.prevent="openModal('ContentType3', 'AGE REVERSE', 'modal-lg')">
              <div class="button-inside-icon">
                <font-awesome-icon width="12" height="12" :icon="['fas', 'book-open']" />
              </div>
              <span class="mx-3 text-uppercase">{{ text.more }}</span>
            </button>
          </div>


        </div>
      </div>
      <div class="row my-5 pt-5 pb-3 d-none d-lg-flex">
        <div class="col-8">
          <div class="row">
            <div class="col-4 box-pr" @click.prevent="scrollTo(1)">
              <div class="row d-flex align-items-center">
                <div class="col-4">
                  <img src="/img/pr-1.png" />
                </div>
                <div class="col-8 border-start border-black border-2 pb-5 ps-2 d-flex flex-column">
                  <strong>YAQOOT</strong>
                  <span class="text-second">Love Cosmedical</span>
                </div>
              </div>
            </div>
            <div class="col-4 box-pr" @click.prevent="scrollTo(2)">
              <div class="row d-flex align-items-center">
                <div class="col-4">
                  <img src="/img/pr-2.png" />
                </div>
                <div class="col-8 border-start border-black border-2 pb-5 ps-2 d-flex flex-column">
                  <strong>PMP</strong>
                  <span class="text-second">Marc Medical</span>
                </div>
              </div>
            </div>
            <div class="col-4 box-pr" @click.prevent="scrollTo(3)">
              <div class="row d-flex align-items-center">
                <div class="col-4">
                  <img src="/img/pr-3.png" />
                </div>
                <div class="col-8 border-start border-black border-2 pb-5 ps-2 d-flex flex-column">
                  <strong>PROFOUND</strong>
                  <span class="text-second">Love Cosmedical</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 d-flex flex-column d-lg-none  product-bg-1-3 px-primary py-5">

      <div class="d-flex align-items-center justify-content-start head-contacts">
          <div class="d-flex align-items-center color-second me-3">
            <font-awesome-icon width="18" height="18" :icon="['fas', 'phone']" />
            <a href="tel:+380969005588" class="ms-2">{{ text.phone }}</a>
          </div>
        </div>
        <div class="d-flex align-items-center justify-content-start mt-3 head-contacts">
          <div class="d-flex align-items-center color-second">
            <font-awesome-icon width="18" height="18" :icon="['fas', 'location-dot']" />
            <span class="ms-2">{{ text.address }}</span>
          </div>
        </div>

        <div class="d-flex mt-4">
          <button type="button" class="btn  rounded-pill main-bg d-flex align-items-center px-2" data-bs-toggle="modal"
          data-bs-target="#exampleModal" @click.prevent="openModal('type-2', 'Консультація', 'modal-md')">
          <div class="button-inside-icon">
            <font-awesome-icon width="12" height="12" :icon="['fas', 'info']" />
          </div>
          <span class="mx-3 text-uppercase">{{ text.consult }}</span>
        </button>
        </div>
        

        

    
    </div>
  </div>
</template>
<script>

export default {
  inject: ['scrollTo'],
  props: {
    text: {
      type: String,
    },
  },
  data() {
    return {
      currentSlogan: [], // This will hold the current slogan displayed
      slogans: ['STAY BEAUTY ', 'STAY YOUNG '], // Only the two specified slogans
      sloganIndex: 0, // Index to keep track of which slogan is currently being displayed
    }
  },
  mounted() {
    this.changeSlogan();
  },
  computed: {

    displaySlogan() {
      return this.currentSlogan; // Computed property to display the slogan
    },


  },
  methods: {



    openModal(type, title, size) {
      this.$emit('open-modal', type, title, size);
    },

    async typeSlogan(slogan) {
      this.currentSlogan = []; // Reset to an empty array
      for (let char of slogan) {
        let charObj = { char: char, opacity: 0.1 };
        this.currentSlogan.push(charObj);

        // Increase the opacity gradually
        while (charObj.opacity < 1) {
          charObj.opacity += 0.1;
          await this.delay(2); // Adjust timing as needed for effect
        }
      }
    },
    delay(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    changeSlogan() {
      this.typeSlogan(this.slogans[this.sloganIndex]);
      setInterval(() => {
        this.sloganIndex = (this.sloganIndex + 1) % this.slogans.length;
        this.typeSlogan(this.slogans[this.sloganIndex]);
      }, 4000 + 5 * this.slogans[this.sloganIndex].length); // Adjust timing based on the slogan length
    },
  },
};
</script>