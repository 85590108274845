<template>
  <div class="w-100">
    <h5>ГЛУТАТІОН</h5>
    <p>Відіграє ключову роль у метаболічних процесах, є потужним антиоксидантом, який забезпечує нейтралізацію активних форм кисню АФК, захист і відновлення клітин. Глутатіон також має протизапальну дію,  відновлює та посилює дію інших антиоксидантів, зокрема вітамінів С та Е.</p>
    <h5>АМІНОКИСЛОТНИЙ КЛАСТЕР</h5>
    <strong>Гліцин - Пролін - Аргінін</strong>
    <p>Амінокислоти мають ізометричну конфігурацію - L-форму, що робить їх максимально біодоступними для організму.</p>
    <p>Гліцин та пролін: основні амінокислоти, що стимулюють синтез колагену та формують його просторову структуру.</p>
    <p>Аргінін: є одним із ключових метаболітів у процесах азотистого обміну організму, відіграє важливу роль у вазодилатації.</p>
    <h5></h5>
    <p></p>
  </div>
</template>