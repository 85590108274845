<template>
    <div class="w-100">
        <h5>АМІНОКИСЛОТНИЙ КЛАСТЕР</h5>
        <strong>Гліцин - Пролін - Аргінін</strong>
        <p>Амінокислоти мають ізометричну конфігурацію - L-форму, що робить їх максимально біодоступними для організму.
        </p>
        <p>Гліцин та пролін: основні амінокислоти, що стимулюють синтез колагену та формують його просторову структуру.
        </p>
        <p>Аргінін: є одним із ключових метаболітів у процесах азотистого обміну організму, відіграє важливу роль у вазодилатації.</p>
        <h5></h5>
        <p></p>
    </div>
</template>