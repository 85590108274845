<template>
    <div class="w-100">
        <h5>TRIPEPTIDE-28 - це новітній пептид, який:</h5>
        <p>Покращує клітинне дихання, запобігаючи деградації клітин внаслідок низької концентрації кисню. Має потужний антиоксидантний потенціал. Має епігенетичну дію і регулює експресію генів.</p>
        <ul>
            <li>стимулює синтез білків у позаклітинному матриксі</li>
            <li>інгібує металопротеїнази, які руйнують колаген</li>
            <li>стимулює вироблення інгібіторів протеаз (ферментів, що розщеплюють білки)</li>
            <li>стимулює синтез інтегринів - специфічних білків, які покращують адгезію клітин до позаклітинного матриксу</li>
        </ul>
        <p>Це призводить до розгладжування та зміцнення шкіри, покращення її еластичності і тонусу, надаючи здорового та молодого вигляду.</p>
        <h5></h5>
        <p></p>
    </div>
</template>