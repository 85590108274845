<template>
  <div class="row">
    <div class="col-12 px-primary py-5">
      <div class="row">
        <div class="col-12 d-flex justify-content-center flex-column text-start text-lg-center">
          <h2 class="mt-3 display-1 sub-title">YAQOOT</h2>
          <span class="text-uppercase color-second desc-title">БІОМОДЕЛЮЮЧИЙ БУСТЕР</span>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-3 d-none d-lg-block mt-5">
          <div class="card border-0 shadow-sm position-relative" v-auto-animate>
            <div class="position-absolute number-plate">01</div>
            <div class="card-body px-4">
              <h5 class="mb-3"><strong>Complex TIMPs L3C1®</strong></h5>
              <p>
                Інноваційний комплекс, який містить інгібітори металопротеїназ, забезпечує значне сповільнення природної
                біодеградації волокон екстрацелюлярного матриксу.
              </p>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="row">
            <div class="col-5 d-none d-lg-flex flex-column mt-5">
              <div class="d-flex justify-content-end mb-3">
                <button type="button" class="btn btn-outline-second rounded-pill text-nowrap font-size-90" data-bs-toggle="modal" data-bs-target="#exampleModal" @click.prevent="openModal('ContentType41', 'Ніацинамід Вітмін В3, В12', 'modal-lg')">
                  <span class="mx-2">Ніацинамід Вітмін В3, В12</span>
                  <font-awesome-icon :icon="['fas', 'chevron-right']" />
                </button>
              </div>
              <div class="d-flex justify-content-end mb-3">
                <button type="button" class="btn btn-outline-second rounded-pill text-nowrap font-size-90" data-bs-toggle="modal" data-bs-target="#exampleModal"  @click.prevent="openModal('ContentType42', 'Аcetyl Hexapeptide-8', 'modal-lg')">
                  <span class="mx-2">Аcetyl Hexapeptide-8</span>
                  <font-awesome-icon :icon="['fas', 'chevron-right']" />
                </button>
              </div>
              <div class="d-flex justify-content-end mb-3">
                <button type="button" class="btn btn-outline-second rounded-pill text-nowrap font-size-90" data-bs-toggle="modal" data-bs-target="#exampleModal"  @click.prevent="openModal('ContentType43', 'Глутатіон, Аргінін', 'modal-lg')">
                  <span class="mx-2">Глутатіон, Аргінін</span>
                  <font-awesome-icon :icon="['fas', 'chevron-right']" />
                </button>
              </div>
              <div class="d-flex justify-content-end mb-3">
                <button type="button" class="btn btn-outline-second rounded-pill text-nowrap font-size-90" data-bs-toggle="modal" data-bs-target="#exampleModal"  @click.prevent="openModal('ContentType44', 'Сапфір', 'modal-lg')">
                  <span class="mx-2">Сапфір</span>
                  <font-awesome-icon :icon="['fas', 'chevron-right']" />
                </button>
              </div>
            </div>
            <div class="col-6 col-lg-2 px-0">
              <div class="row bg-pr-1 px-2 py-5">
                <div class="col-12">
                  <img class="w-100" src="/img/pr-1.png"/>
                </div>
              </div>
            </div>
            <div class="col-5 d-none d-lg-flex flex-column mt-5">
              <div class="d-flex justify-content-start mb-3">
                <button type="button" class="btn btn-outline-second rounded-pill text-nowrap font-size-90" data-bs-toggle="modal" data-bs-target="#exampleModal"  @click.prevent="openModal('ContentType45', 'Гіалуронова кислота, пролін', 'modal-lg')">
                  <font-awesome-icon :icon="['fas', 'chevron-left']" />
                  <span class="mx-2">Гіалуронова кислота, пролін</span>
                </button>
              </div>
              <div class="d-flex justify-content-start mb-3">
                <button type="button" class="btn btn-outline-second rounded-pill text-nowrap font-size-90" data-bs-toggle="modal" data-bs-target="#exampleModal"  @click.prevent="openModal('ContentType46', 'Бурштинова кислота', 'modal-lg')">
                  <font-awesome-icon :icon="['fas', 'chevron-left']" />
                  <span class="mx-2">Бурштинова кислота</span>
                </button>
              </div>
              <div class="d-flex justify-content-start mb-3">
                <button type="button" class="btn btn-outline-second rounded-pill text-nowrap font-size-90" data-bs-toggle="modal" data-bs-target="#exampleModal"  @click.prevent="openModal('ContentType47', 'Tripeptide-28', 'modal-lg')">
                  <font-awesome-icon :icon="['fas', 'chevron-left']" />
                  <span class="mx-2">Tripeptide-28</span>
                </button>
              </div>
              <div class="d-flex justify-content-start mb-3">
                <button type="button" class="btn btn-outline-second rounded-pill text-nowrap font-size-90" data-bs-toggle="modal" data-bs-target="#exampleModal"  @click.prevent="openModal('ContentType48', 'Гліцин', 'modal-lg')">
                  <font-awesome-icon :icon="['fas', 'chevron-left']" />
                  <span class="mx-2">Гліцин</span>
                </button>
              </div>
            </div>
            <div class="col-6 d-flex d-lg-none flex-column mt-5">
              <div class="d-flex justify-content-start mb-2">
                <button type="button" class="btn btn-outline-second rounded-pill text-nowrap font-size-90" data-bs-toggle="modal" data-bs-target="#exampleModal"  @click.prevent="openModal('ContentType45', 'Гіалуронова кислота, пролін', 'modal-lg')">
                  <font-awesome-icon :icon="['fas', 'chevron-left']" />
                  <span class="mx-2">Гіалуронова кислота, пролін</span>
                </button>
              </div>
              <div class="d-flex justify-content-start mb-2">
                <button type="button" class="btn btn-outline-second rounded-pill text-nowrap font-size-90" data-bs-toggle="modal" data-bs-target="#exampleModal" @click.prevent="openModal('ContentType41', 'Ніацинамід Вітмін В3, В12', 'modal-lg')">
                  <font-awesome-icon :icon="['fas', 'chevron-left']" />
                  <span class="mx-2">Ніацинамід Вітмін В3, В12</span>
                </button>
              </div>
              
              <div class="d-flex justify-content-start mb-2">
                <button type="button" class="btn btn-outline-second rounded-pill text-nowrap font-size-90" data-bs-toggle="modal" data-bs-target="#exampleModal"  @click.prevent="openModal('ContentType42', 'Аcetyl Hexapeptide-8', 'modal-lg')">
                  <font-awesome-icon :icon="['fas', 'chevron-left']" />
                  <span class="mx-2">Аcetyl Hexapeptide-8</span>
                </button>
              </div>
              <div class="d-flex justify-content-start mb-2">
                <button type="button" class="btn btn-outline-second rounded-pill text-nowrap font-size-90" data-bs-toggle="modal" data-bs-target="#exampleModal"  @click.prevent="openModal('ContentType46', 'Бурштинова кислота', 'modal-lg')">
                  <font-awesome-icon :icon="['fas', 'chevron-left']" />
                  <span class="mx-2">Бурштинова кислота</span>
                </button>
              </div>
              <div class="d-flex justify-content-start mb-2">
                <button type="button" class="btn btn-outline-second rounded-pill text-nowrap font-size-90" data-bs-toggle="modal" data-bs-target="#exampleModal"  @click.prevent="openModal('ContentType43', 'Глутатіон, Аргінін', 'modal-lg')">
                  <font-awesome-icon :icon="['fas', 'chevron-left']" />
                  <span class="mx-2">Глутатіон, Аргінін</span>
                </button>
              </div>
              <div class="d-flex justify-content-start mb-2">
                <button type="button" class="btn btn-outline-second rounded-pill text-nowrap font-size-90" data-bs-toggle="modal" data-bs-target="#exampleModal"  @click.prevent="openModal('ContentType47', 'Tripeptide-28', 'modal-lg')">
                  <font-awesome-icon :icon="['fas', 'chevron-left']" />
                  <span class="mx-2">Tripeptide-28</span>
                </button>
              </div>
              <div class="d-flex justify-content-start mb-2">
                <button type="button" class="btn btn-outline-second rounded-pill text-nowrap font-size-90" data-bs-toggle="modal" data-bs-target="#exampleModal"  @click.prevent="openModal('ContentType48', 'Гліцин', 'modal-lg')">
                  <font-awesome-icon :icon="['fas', 'chevron-left']" />
                  <span class="mx-2">Гліцин</span>
                </button>
              </div>
              <div class="d-flex justify-content-start mb-2">
                <button type="button" class="btn btn-outline-second rounded-pill text-nowrap font-size-90" data-bs-toggle="modal" data-bs-target="#exampleModal"  @click.prevent="openModal('ContentType44', 'Сапфір', 'modal-lg')">
                  <font-awesome-icon :icon="['fas', 'chevron-left']" />
                  <span class="mx-2">Сапфір</span>
                </button>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 d-flex justify-content-start justify-content-lg-center">
              <button type="button" class="btn  rounded-pill second-bg d-flex align-items-center px-2"
                data-bs-toggle="modal" data-bs-target="#exampleModal"  @click.prevent="openModal('ContentTypeVideo4', 'YAQOOT', 'modal-xl')">
                <div class="button-inside-icon">
                  <font-awesome-icon :icon="['fas', 'play']" />
                </div>
                <span class="mx-3 text-uppercase">{{ text.video }}</span>
              </button>
            </div>
          </div>
        </div>
        <div class="col-12 d-flex d-lg-none mt-5">
          <div class="card border-0 shadow-sm position-relative">
            <div class="position-absolute number-plate">01</div>
            <div class="card-body px-4">
              <h5 class="mb-3"><strong>Complex TIMPs L3C1®</strong></h5>
              <p>
                Інноваційний комплекс, який містить інгібітори металопротеїназ, забезпечує значне сповільнення природної
                біодеградації волокон екстрацелюлярного матриксу.
              </p>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-3 mt-5">
          <div class="card border-0 shadow-sm position-relative">
            <div class="position-absolute number-plate">02</div>
            <div class="card-body px-4">
              <h5 class="mb-3"><strong>NANO HYBRID TECHNOLOGY®</strong></h5>
              <p>
                Використання наногібридної технології дозволяє збільшити біодоступність, ефективність та стабільність пептидів, які входять до складу препарату YAQOOT. 
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-5 d-flex align-items-end">
        <div class="col-3 d-none d-lg-flex rubin-bg">
          <img src="/img/rubin-1.png"/>
        </div>
        <div class="col-12 col-lg-6">
          <div class="card border-0 shadow-sm">
            <div class="card-head">
              <nav>
                <div class="nav nav-tabs border-bottom border-2" id="nav-tab" role="tablist">
                  <button class="nav-link p-4 border-0 active" id="nav-home-tab-1" data-bs-toggle="tab"
                    data-bs-target="#nav-home-1" type="button" role="tab" aria-controls="nav-home-1"
                    aria-selected="true">Glow-ефект</button>
                  <button class="nav-link p-4 border-0" id="nav-profile-tab-1" data-bs-toggle="tab"
                    data-bs-target="#nav-profile-1" type="button" role="tab" aria-controls="nav-profile-1"
                    aria-selected="false">Застосування</button>
                </div>
              </nav>
            </div>
            <div class="card-body px-4">
              <div class="tab-content" id="nav-tabContent">
                <div class="tab-pane fade show active" id="nav-home-1" role="tabpanel" aria-labelledby="nav-home-tab-1"
                  tabindex="0">
                  <p> Синергічна дія активних інгредієнтів: однорідний колір, біостимуляція, anti age, омолодження,
                    розгладжування зморшок.</p>

                </div>
                <div class="tab-pane fade" id="nav-profile-1" role="tabpanel" aria-labelledby="nav-profile-tab-1"
                  tabindex="0">
                  <h5>Рекомендований протокол застосування</h5>
                  <p>3-5 процедур з інтервалом 10-14 днів</p>
                  <h5>Рекомендований розмір голки</h5>
                  <p>Стандартна голка - 32G4</p>
                  <h5>Техніка введення</h5>
                  <p>Інтрадермально</p>
                  <h5>Дозування</h5>
                  <p>2,5 мл препарату/одна зона (обличчя)</p>
                
                </div>

              </div>
            </div>
          </div>


        </div>
        <div class="col-3  d-none d-lg-flex rubin-bg text-end">
          <img src="/img/rubin-2.png"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  props: {
    text: {
      type: String,
    },
  },
  data() {
    return {

    }
  },
  mounted() {

  },
  computed: {

  },
  methods: {
    openModal(type, title, size){
      this.$emit('open-modal', type, title, size);
    },

  },

};
</script>