<template>
  <div class="row">
    <div class="col-12 px-primary py-5 mb-5">
      <div class="row">
        <div class="col-12 col-lg-6">
          <div class="row">
            <div class="col-12 d-flex justify-content-center flex-column text-start text-lg-end">
              <h2 class="mt-3 display-1 sub-title">PROFOUND</h2>
              <span class="text-uppercase color-second desc-title">БІОМОДЕЛЮЮЧИЙ БУСТЕР</span>
            </div>
          </div>
          <div class="row mt-4 d-lg-none">
            <div class="col-6 px-0">
              <div class="row bg-pr-3 px-2 py-5">
                <div class="col-12">
                  <img class="w-100" src="/img/pr-3.png" />
                </div>
              </div>
            </div>
            <div class="col-6 d-flex flex-column mt-5">
              <div class="d-flex justify-content-start mb-3">
                <button type="button" class="btn btn-outline-second rounded-pill text-nowrap font-size-90" data-bs-toggle="modal"
                  data-bs-target="#exampleModal" @click.prevent="openModal('ContentType65', 'Кластерний комплекс H-HA/L-HA', 'modal-lg')">
                  <font-awesome-icon :icon="['fas', 'chevron-left']" />
                  <span class="mx-2">Кластерний комплекс H-HA/L-HA</span>
                </button>
              </div>
              <div class="d-flex justify-content-start mb-3">
                <button type="button" class="btn btn-outline-second rounded-pill text-nowrap font-size-90" data-bs-toggle="modal"
                  data-bs-target="#exampleModal" @click.prevent="openModal('ContentType61', 'Амінокислотний комплекс', 'modal-lg')">
                  <font-awesome-icon :icon="['fas', 'chevron-left']" />
                  <span class="mx-2">Амінокислотний комплекс</span>
                </button>
              </div>
              <div class="d-flex justify-content-start mb-3">
                <button type="button" class="btn btn-outline-second rounded-pill text-nowrap font-size-90" data-bs-toggle="modal"
                  data-bs-target="#exampleModal" @click.prevent="openModal('ContentType66', 'Ніацинамід вітамін В3', 'modal-lg')">
                  <font-awesome-icon :icon="['fas', 'chevron-left']" />
                  <span class="mx-2">Ніацинамід вітамін В3</span>
                </button>
              </div>
              <div class="d-flex justify-content-start mb-3">
                <button type="button" class="btn btn-outline-second rounded-pill text-nowrap font-size-90" data-bs-toggle="modal"
                  data-bs-target="#exampleModal" @click.prevent="openModal('ContentType62', 'Ацетил гексапептид 8', 'modal-lg')">
                  <font-awesome-icon :icon="['fas', 'chevron-left']" />
                  <span class="mx-2">Ацетил гексапептид 8</span>
                </button>
              </div>
              <div class="d-flex justify-content-start mb-3">
                <button type="button" class="btn btn-outline-second rounded-pill text-nowrap font-size-90" data-bs-toggle="modal"
                  data-bs-target="#exampleModal" @click.prevent="openModal('ContentType67', 'N-ацетил L-цистеїн', 'modal-lg')">
                  <font-awesome-icon :icon="['fas', 'chevron-left']" />
                  <span class="mx-2">N-ацетил L-цистеїн</span>
                </button>
              </div>
              <div class="d-flex justify-content-start mb-3">
                <button type="button" class="btn btn-outline-second rounded-pill text-nowrap font-size-90" data-bs-toggle="modal"
                  data-bs-target="#exampleModal" @click.prevent="openModal('ContentType63', 'Ресвератрол', 'modal-lg')">
                  <font-awesome-icon :icon="['fas', 'chevron-left']" />
                  <span class="mx-2">Ресвератрол</span>
                </button>
              </div>
              <div class="d-flex justify-content-start mb-3">
                <button type="button" class="btn btn-outline-second rounded-pill text-nowrap font-size-90" data-bs-toggle="modal"
                  data-bs-target="#exampleModal" @click.prevent="openModal('ContentType68', 'Манітол', 'modal-lg')">
                  <font-awesome-icon :icon="['fas', 'chevron-left']" />
                  <span class="mx-2">Манітол</span>
                </button>
              </div>
              <div class="d-flex justify-content-start mb-3">
                <button type="button" class="btn btn-outline-second rounded-pill text-nowrap font-size-90" data-bs-toggle="modal"
                  data-bs-target="#exampleModal" @click.prevent="openModal('ContentType64', 'DMAE', 'modal-lg')">
                  <font-awesome-icon :icon="['fas', 'chevron-left']" />
                  <span class="mx-2">DMAE</span>
                </button>
              </div>
            </div>
          </div>
          <div class="row w-100 d-lg-none">
            <div class="col-12 d-flex justify-content-start">
              <button type="button" class="btn  rounded-pill second-bg d-flex align-items-center px-2"
                data-bs-toggle="modal" data-bs-target="#exampleModal" @click.prevent="openModal('ContentTypeVideo6', 'PROFOUND', 'modal-xl')">
                <div class="button-inside-icon">
                  <font-awesome-icon :icon="['fas', 'play']" />
                </div>
                <span class="mx-3 text-uppercase">{{ text.video }}</span>
              </button>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-12">
              <div class="card border-0 shadow-sm">
                <div class="card-head">
                  <nav>
                    <div class="nav nav-tabs border-bottom border-2" id="nav-tab" role="tablist">
                      <button class="nav-link p-4 border-0 active" id="nav-home-tab-3" data-bs-toggle="tab"
                        data-bs-target="#nav-home-3" type="button" role="tab" aria-controls="nav-home-3"
                        aria-selected="true">Переваги</button>
                      <button class="nav-link p-4 border-0" id="nav-profile-tab-3" data-bs-toggle="tab"
                        data-bs-target="#nav-profile-3" type="button" role="tab" aria-controls="nav-profile-3"
                        aria-selected="false">Дія</button>
                      <button class="nav-link p-4 border-0" id="nav-page-tab-3-1" data-bs-toggle="tab"
                        data-bs-target="#nav-page-3-1" type="button" role="tab" aria-controls="nav-page-3-1"
                        aria-selected="false">Застосування</button>
                    </div>
                  </nav>
                </div>
                <div class="card-body px-4">
                  <div class="tab-content" id="nav-tabContent">
                    <div class="tab-pane fade show active" id="nav-home-3" role="tabpanel" aria-labelledby="nav-home-tab-3"
                      tabindex="0">
                      <p> Синергічна дія активних інгредієнтів: однорідний колір, біостимуляція, anti age, омолодження,
                        розгладжування зморшок.</p>
                        <ul>
                          <li>Ефективність</li>
                          <li>Гармонійна формула та збалансований склад</li>
                          <li>Гіалуронова кислота ультратонкого очищення у складі препарату з концентрацією 45 мг на 3 мл препарату</li>
                          <li>Екологічність — відсутність BDDE, DVS та інших хімічних сполук</li>
                          <li>Апірогенність</li>
                          <li>Висока біодоступність та біосумісність активних компонентів</li>
                          <li>Препарат розроблений та вироблений в Італії</li>
                          <li>Справедлива ціна</li>
                        </ul>
                    </div>
                    <div class="tab-pane fade" id="nav-profile-3" role="tabpanel" aria-labelledby="nav-profile-tab-3"
                      tabindex="0">
                      <ul>
                          <li>Ефективне зволоження</li>
                          <li>Боротьба з фото/хроностарінням</li>
                          <li>Реконструкція клітинного матриксу</li>
                          <li>Стимулювання: фібробласти - кератиноцити</li>
                          <li>Інактивація афк</li>
                          <li>Ботокс-ефект</li>
                        </ul>
                      
                    </div>
                    <div class="tab-pane fade" id="nav-page-3-1" role="tabpanel" aria-labelledby="nav-page-tab-3-1"
                      tabindex="0">
                      <h5>Зони</h5>
                      <p>Процедура проводиться в таких зонах: обличчя, шия, декольте, руки.</p>
                      <h5>Частота</h5>
                      <p>Для молодої шкіри достатньо 2 процедури, для зрілої шкіри — 3-4 процедури. Процедура проводиться 1 раз на 2 тижні. Курс необхідно повторити за 4-6 місяців або проводити підтримуючу процедуру 1 раз на місяць.</p>
                      <h5>Дозування</h5>
                      <p>Дозування для однієї процедури — 1 мл препарату на одну зону (обличчя, шия/декольте, руки) для молодої шкіри; 1,5 мл — для вікової шкіри. </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6 d-none d-lg-flex align-items-end flex-column">
          <div class="row w-100 mt-pr">
            <div class="col-5 d-flex flex-column mt-5">
              <div class="d-flex justify-content-end mb-3">
                <button type="button" class="btn btn-outline-second rounded-pill text-nowrap font-size-90" data-bs-toggle="modal"
                  data-bs-target="#exampleModal" @click.prevent="openModal('ContentType61', 'Амінокислотний комплекс', 'modal-lg')">
                  <span class="mx-2">Амінокислотний комплекс</span>
                  <font-awesome-icon :icon="['fas', 'chevron-right']" />
                </button>
              </div>
              <div class="d-flex justify-content-end mb-3">
                <button type="button" class="btn btn-outline-second rounded-pill text-nowrap font-size-90" data-bs-toggle="modal"
                  data-bs-target="#exampleModal" @click.prevent="openModal('ContentType62', 'Ацетил гексапептид 8', 'modal-lg')">
                  <span class="mx-2">Ацетил гексапептид 8</span>
                  <font-awesome-icon :icon="['fas', 'chevron-right']" />
                </button>
              </div>
              <div class="d-flex justify-content-end mb-3">
                <button type="button" class="btn btn-outline-second rounded-pill text-nowrap font-size-90" data-bs-toggle="modal"
                  data-bs-target="#exampleModal" @click.prevent="openModal('ContentType63', 'Ресвератрол', 'modal-lg')">
                  <span class="mx-2">Ресвератрол</span>
                  <font-awesome-icon :icon="['fas', 'chevron-right']" />
                </button>
              </div>
              <div class="d-flex justify-content-end mb-3">
                <button type="button" class="btn btn-outline-second rounded-pill text-nowrap font-size-90" data-bs-toggle="modal"
                  data-bs-target="#exampleModal" @click.prevent="openModal('ContentType64', 'DMAE', 'modal-lg')">
                  <span class="mx-2">DMAE</span>
                  <font-awesome-icon :icon="['fas', 'chevron-right']" />
                </button>
              </div>
            </div>
            <div class="col-2 px-0">
              <div class="row bg-pr-3 px-2 py-5">
                <div class="col-12">
                  <img class="w-100" src="/img/pr-3.png" />
                </div>
              </div>
            </div>
            <div class="col-5 d-flex flex-column mt-5">
              <div class="d-flex justify-content-start mb-3">
                <button type="button" class="btn btn-outline-second rounded-pill text-nowrap font-size-90" data-bs-toggle="modal"
                  data-bs-target="#exampleModal" @click.prevent="openModal('ContentType65', 'Кластерний комплекс H-HA/L-HA', 'modal-lg')">
                  <font-awesome-icon :icon="['fas', 'chevron-left']" />
                  <span class="mx-2">Кластерний комплекс H-HA/L-HA</span>
                </button>
              </div>
              <div class="d-flex justify-content-start mb-3">
                <button type="button" class="btn btn-outline-second rounded-pill text-nowrap font-size-90" data-bs-toggle="modal"
                  data-bs-target="#exampleModal" @click.prevent="openModal('ContentType66', 'Ніацинамід вітамін В3', 'modal-lg')">
                  <font-awesome-icon :icon="['fas', 'chevron-left']" />
                  <span class="mx-2">Ніацинамід вітамін В3</span>
                </button>
              </div>
              <div class="d-flex justify-content-start mb-3">
                <button type="button" class="btn btn-outline-second rounded-pill text-nowrap font-size-90" data-bs-toggle="modal"
                  data-bs-target="#exampleModal" @click.prevent="openModal('ContentType67', 'N-ацетил L-цистеїн', 'modal-lg')">
                  <font-awesome-icon :icon="['fas', 'chevron-left']" />
                  <span class="mx-2">N-ацетил L-цистеїн</span>
                </button>
              </div>
              <div class="d-flex justify-content-start mb-3">
                <button type="button" class="btn btn-outline-second rounded-pill text-nowrap font-size-90" data-bs-toggle="modal"
                  data-bs-target="#exampleModal" @click.prevent="openModal('ContentType68', 'Манітол', 'modal-lg')">
                  <font-awesome-icon :icon="['fas', 'chevron-left']" />
                  <span class="mx-2">Манітол</span>
                </button>
              </div>
            </div>
          </div>
          <div class="row w-100">
            <div class="col-12 d-flex justify-content-center">
              <button type="button" class="btn  rounded-pill second-bg d-flex align-items-center px-2"
                data-bs-toggle="modal" data-bs-target="#exampleModal" @click.prevent="openModal('ContentTypeVideo6', 'PROFOUND', 'modal-xl')">
                <div class="button-inside-icon">
                  <font-awesome-icon :icon="['fas', 'play']" />
                </div>
                <span class="mx-3 text-uppercase">{{ text.video }}</span>
              </button>
            </div>
          </div>
          <div class="row w-100 mt-auto">
            <div class="col-12 pr-height-bg text-end">
              <img src="/img/molecul.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  props: {
    text: {
      type: String,
    },
  },
  data() {
    return {

    }
  },
  mounted() {

  },
  computed: {

  },
  methods: {

    openModal(type, title, size){
      this.$emit('open-modal', type, title, size);
    },
  },

};
</script>