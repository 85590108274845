<template>
    <div class="modal fade" :id="modalId" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-fullscreen-md-down" :class="size">
            <div class="modal-content">
                <div class="modal-header px-modal">
                    <h1 class="modal-title fs-5" id="exampleModalLabel">{{ title }}</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body px-modal">
                    <form v-if="type == 'type-2'" id="contactForm">
                        <div class="row mb-3">
                            <div class="col-12">
                                <div class="row d-flex align-items-center">
                                    <div class="col-3">
                                        <label for="name" class="form-label">Ім'я</label>
                                    </div>
                                    <div class="col-9">
                                        <input type="text" class="form-control" placeholder="Введіть ваше ім'я"
                                            id="name" v-model="formData.name">
                                        <small v-if="errors['name']" class="text-danger">Поле обов'язкове</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-12">
                                <div class="row d-flex align-items-center">
                                    <div class="col-3">
                                        <label for="phone" class="form-label">Телефон</label>
                                    </div>
                                    <div class="col-9">
                                        <input type="tel" class="form-control" placeholder="Введіть номер телефону"
                                            id="phone" v-model="formData.phone">
                                        <small v-if="errors['phone']" class="text-danger">Поле обов'язкове</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-12">
                                <div class="row d-flex align-items-center">
                                    <div class="col-3">
                                        <label for="phone" class="form-label">E-mail</label>
                                    </div>
                                    <div class="col-9">
                                        <input type="tel" class="form-control" placeholder="Введіть вашу пошту"
                                            id="phone" v-model="formData.email">
                                        <small v-if="errors['email']" class="text-danger">Поле обов'язкове</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-12">
                                <div class="row d-flex align-items-center">
                                    <div class="col-3">
                                        <label for="message" class="form-label">Тема</label>
                                    </div>
                                    <div class="col-9">
                                        <textarea class="form-control" placeholder="Напишіть ваше питання" id="message"
                                            rows="3" v-model="formData.message"></textarea>
                                        <small v-if="errors['message']" class="text-danger">Поле обов'язкове</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div v-else class="row">
                        <div class="col-12">
                            <component :is="type"></component>
                        </div>
                    </div>
                </div>
                <div class="modal-footer px-modal" v-if="type == 'type-2'">
                    <button type="button" class="btn main-bg rounded-pill" @click="sendEmail">{{ text.submit }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import axios from 'axios';

import ContentType3 from './content/ContentType3.vue';
import ContentType41 from './content/ContentType41.vue';
import ContentType42 from './content/ContentType42.vue';
import ContentType43 from './content/ContentType43.vue';
import ContentType44 from './content/ContentType44.vue';
import ContentType45 from './content/ContentType45.vue';
import ContentType46 from './content/ContentType46.vue';
import ContentType47 from './content/ContentType47.vue';
import ContentType48 from './content/ContentType48.vue';

import ContentType51 from './content/ContentType51.vue';
import ContentType52 from './content/ContentType52.vue';
import ContentType53 from './content/ContentType53.vue';
import ContentType54 from './content/ContentType54.vue';
import ContentType55 from './content/ContentType55.vue';
import ContentType56 from './content/ContentType56.vue';
import ContentType57 from './content/ContentType57.vue';
import ContentType58 from './content/ContentType58.vue';

import ContentType61 from './content/ContentType61.vue';
import ContentType62 from './content/ContentType62.vue';
import ContentType63 from './content/ContentType63.vue';
import ContentType64 from './content/ContentType64.vue';
import ContentType65 from './content/ContentType65.vue';
import ContentType66 from './content/ContentType66.vue';
import ContentType67 from './content/ContentType67.vue';
import ContentType68 from './content/ContentType68.vue';

import ContentTypeVideo4 from './content/ContentTypeVideo4.vue';
import ContentTypeVideo5 from './content/ContentTypeVideo5.vue';
import ContentTypeVideo6 from './content/ContentTypeVideo6.vue';

export default {
    name: "AppModal",
    components: {
        ContentType3,
        ContentType41,
        ContentType42,
        ContentType43,
        ContentType44,
        ContentType45,
        ContentType46,
        ContentType47,
        ContentType48,

        ContentType51,
        ContentType52,
        ContentType53,
        ContentType54,
        ContentType55,
        ContentType56,
        ContentType57,
        ContentType58,

        ContentType61,
        ContentType62,
        ContentType63,
        ContentType64,
        ContentType65,
        ContentType66,
        ContentType67,
        ContentType68,



        ContentTypeVideo4,
        ContentTypeVideo5,
        ContentTypeVideo6,
    },
    props: {
        modalId: {
            type: String,
            required: true
        },
        type: {
            type: String,
            default: 'type-1'
        },
        modalAlignment: {
            type: String,
            default: 'top'
        },
        size: {
            type: String,
            default: 'modal-md',
        },
        title: {
            type: String,
            default: 'Modal Title',
        },
        text: {
            type: String
        },


    },
    data() {
        return {
            errors: {},
            formData: {
                name: '',
                phone: '',
                email: '',
                message: '',
                subject: 'Site send'
            },
        };
    },
    mounted() {
        const myModal = document.getElementById(this.modalId);
    myModal.addEventListener('hide.bs.modal', this.stopVideo);

    },

    methods: {

        stopVideo() {
      const video = document.getElementById('myVideo');
      if (video) {
        video.pause();
        video.currentTime = 0; // Optionally, rewind the video
      }
    },


        async sendEmail() {

            try {
                const response = await axios.post('http://mojito.local/functions/send-email.php', this.formData, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                });
                console.log('response', response);
                alert('Email sent successfully!');

            } catch (error) {
                console.log(error.response.data.errors);
                this.errors = {};

                // Transform error array into object
                error.response.data.errors.forEach(field => {
                    this.errors[field] = true;
                });

            }
        }

    }
}
</script>
