<template>
  <div id="app">
    <div class="page-body-wrapper">
      <div class="position-fixed w-100 main-menu d-none d-lg-flex">
        <div class="col-12 px-primary py-3">
          <div class="row">
            <div class="col-12 col-lg-8 col-xl-7">
              <div class="position-relative">
                <div class="progressline"
                  :style="{ background: 'linear-gradient(to right, #231D19 0%, #231D19 70%, #A59A93 100%)', width: sizeForBar, marginLeft: marginMenu, minWidth: '10%' }">
                </div>
                <div class="progressin" :style="{ background: '#A59A93', width: sizeForMenu, marginLeft: marginMenu }">
                </div>
                <ul class="nav justify-content-start position-absolute menu-nav">
                  <li class="nav-item" v-for="(item, index) in menuItems" :key="index"
                    :style="{ width: allSize[index] + 'px' }" :ref="`menuItem-${index}`"
                    :class="{ 'active-menu': activeBlock >= index, 'selected-menu': activeBlock == index }">
                    <a class="nav-link d-flex flex-column justify-content-center main-color tr-1" href="#"
                      @click.prevent="scrollTo(index)" :class="{ 'mx-4': index > 0, 'ps-0': index == 0 }">
                      <span class="text-nowrap" :class="{'menu-target':activeBlock == index, 'menu-not-target':activeBlock != index}">{{ item.name }}</span>

                      <div class="button-icon tr-1  d-flex align-items-center justify-content-center" :class="{ 'button-icon-mini': activeBlock != index }">
                        <font-awesome-icon class="fa-sm" :icon="['fas', item.icon]" />
                      </div>
                    </a>
                  </li>
                </ul>
              </div>

            </div>
            <div class="col-12 col-lg-4 col-xl-5 d-flex justify-content-end mt-2 flex-column">
              <div class="d-flex justify-content-end">
                <button type="button" class="btn  rounded-pill main-bg d-flex align-items-center px-2"
                  data-bs-toggle="modal" data-bs-target="#exampleModal" @click.prevent="openModal('type-2', 'Консультація', 'modal-md')">
                  <div class="button-inside-icon">
                    <font-awesome-icon width="12" height="12" :icon="['fas', 'info']" />
                  </div>
                  <span class="mx-3 text-uppercase">{{ text.consult }}</span>
                </button>
              </div>
              <div class="d-flex align-items-center justify-content-end mt-2 head-contacts">
                <div class="d-none d-xl-flex align-items-center color-second me-3">
                  <font-awesome-icon width="18" height="18" :icon="['fas', 'phone']" />
                  <a href="tel:+380969005588" class="ms-2">{{ text.phone }}</a>
                </div>
                <div class="d-flex align-items-center color-second">
                  <font-awesome-icon width="18" height="18" :icon="['fas', 'location-dot']" />
                  <span class="ms-2">{{ text.address }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col-12">
          <div class="section" v-for="(section, index) in menuItems" :key="section.name" :ref="'section' + index">
            <component :is="section.component" :text="text" @open-modal="openModal"></component>
          </div>
        </div>
      </div>
      <FooterComponent :text="text" />

      <AppModal :modal-class="'modal-md'" :type="type" :text="text" :title="modalTitle" :size="modalSize" :modal-id="'exampleModal'"/>
    </div>
    <vue-preloader background-color="#a59a93" color="#ffffff" transition-type="fade-down" :loading-speed="10"
      :transition-speed="300" @loading-is-over="showAnimation = false" :overflowActive="false">
      <template v-slot="{ color, percent }">
        <transition name="loading-animation" mode="in-out">
          <div class="d-flex w-100 justify-content-center">
            <div class="col-8 col-lg-6">
              <div class="w-100 text-center mb-4" v-if="percent < 100" :style="{ color }">
                <h1 class="display-1 logo-prealoder" :class="{'show': percent > 5}"><strong>AGE REVERSE</strong></h1>
              </div>
              <div class="prealoder-progress w-100 position-relative" :style="{ height: '2px', background: '#7a6f69' }">
                <div class="progress-line position-absolute text-center"
                  :style="{ background: '#fff', color, height: '2px', width: percent + '%' }"></div>
              </div>
              <div class="w-100 text-center mt-3" v-if="percent < 100" :style="{ color }">
                <h1> {{ percent }}% </h1>
              </div>
            </div>
          </div>
        </transition>
      </template>
    </vue-preloader>
  </div>
</template>

<script>

import ProductSection1 from './components/ProductSection1.vue';
import ProductSection2 from './components/ProductSection2.vue';
import ProductSection3 from './components/ProductSection3.vue';
import ProductSection4 from './components/ProductSection4.vue';
import FooterComponent from './components/FooterComponent.vue';

import { VuePreloader } from "vue-preloader";
import '../node_modules/vue-preloader/dist/style.css'
import { ref } from 'vue';

const showAnimation = ref(true)
/* eslint-disable no-undef */
export default {
  name: 'App',
  components: {
    VuePreloader,
    ProductSection1,
    ProductSection2,
    ProductSection3,
    ProductSection4,
    FooterComponent
  },
  data() {
    return {
      showAnimation,
      isLoading: true,
      type: 'type-1',
      modalTitle: '',
      modalSize: '',
      menuItems: [
        {
          id: 1,
          name: 'AGE REVERSE',
          icon: 'house',
          content: 'Textx .....',
          component: 'ProductSection1',
        },
        {
          id: 2,
          name: 'YAQOOT',
          icon: 'droplet',
          content: 'Textx .....',
          component: 'ProductSection2',
        },
        {
          id: 3,
          name: 'PMP',
          icon: 'droplet',
          content: 'Textx .....',
          component: 'ProductSection3',
        },
        {
          id: 4,
          name: 'PROFOUND',
          icon: 'droplet',
          content: 'Textx .....',
          component: 'ProductSection4',
        },
      ],
      sizeForMenu: 0,
      sizeForBar: '10px',
      marginMenu: 0,
      activeBlock: 0,
      sumLine: 0,
      text: {
        currentYear: new Date().getFullYear(),
        consult: 'Консультація',
        address: 'м. Київ, вул. Хрещатик 19, офіс 202',
        phone: '+38096 900 55 88',
        copywrite: 'All rights reserved. Website created by',
        submit: 'ЗАМОВИТИ',
        video: 'ДИВИТИСЬ ВІДЕО',
        more: 'Детальніше',
      },
      allSize: [],
      isModalActive: false,
    }
  },
  mounted() {
    document.title = "AGE REVERSE - STAY YOUNG";
    window.addEventListener('scroll', this.onScroll);
      window.addEventListener('resize', this.calculateMenuWidths);
   
    setTimeout(() => {
      
      this.calculateMenuWidths();
    }, 400);

  },

  computed: {
    gradientBackground() {
      // Calculate the gradient stop based on the activeBlock
      const stop = (this.activeBlock + 1) / this.menuItems.length * 100;

      return `linear-gradient(to right, #000 0%, #000 ${stop}%, #fff 100%)`;
      //return `linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) ${stop}, rgba(255,255,255,1) 100%)`;
    },
  },
  provide() {
    return {
      scrollTo: this.scrollTo,
    }
  },
  methods: {
    openModal(type, title, size) {
      this.type = type;
      this.modalTitle = title;
      this.modalSize = size;
    },

    closeModal() {
      this.isModalActive = false;
    },
    calculateMenuWidths() {
      this.$nextTick(() => {
        let sum = 0;
        let offsets = [];
        this.menuItems.forEach((_, index) => {
          const refName = `menuItem-${index}`;
          const menuItem = this.$refs[refName][0];
          if (menuItem) {
            const rect = menuItem.getBoundingClientRect();
            sum += rect.width;
            offsets.push(rect.width);
          } else {
            offsets.push(0);
          }
        });

        this.allSize = offsets;



        const activeItemWidth = sum - ((offsets[0] / 2) + (offsets[offsets.length - 1] / 2));

        this.sumLine = activeItemWidth;

        const activeItemOffset = offsets[0] / 2;

        // Update the size and margin to center the bar under the active item
        this.sizeForMenu = `${activeItemWidth}px`;
        this.marginMenu = `${activeItemOffset}px`;

      });
    },
    scrollTo(index) {

      const section = this.$refs[`section${index}`][0];
      const sectionTop = section.offsetTop;

      window.scrollTo({
        top: sectionTop - 100,
        behavior: 'smooth'
      });
    },
    onScroll() {
      const sections = this.menuItems.map((_, index) => this.$refs[`section${index}`][0]);
      const scrollY = window.scrollY;

      const totalHeight = document.documentElement.scrollHeight - window.innerHeight;
      const percent = window.scrollY / totalHeight * 100;

      const sizePixel = this.sumLine / 100 * percent;

      if (percent > 10) {
        this.sizeForBar = `${sizePixel}px`;

      }

      

      // Determine which section is currently in view
      for (let i = 0; i < sections.length; i++) {
        
        const section = sections[i];
        console.log('section', i);
        console.log('scrollY', scrollY);
        console.log('offsetTop', section.offsetTop);
        console.log('offsetHeight', section.offsetHeight);
        console.log('sum', section.offsetTop + section.offsetHeight);
        if (scrollY >= section.offsetTop && scrollY < section.offsetTop + section.offsetHeight) {
          
          //this.activeBlock = i;
          if(scrollY >= (section.offsetTop + section.offsetHeight - 110)){
            this.activeBlock = i + 1;
          }
          if(scrollY < 500){
            this.activeBlock = 0;
          }


          break;
        }
      }
    }
  },
}
</script>
