<template>
    <i :data-feather="name"></i>
  </template>
  
  <script>
  import Icon from "../icon/Icon";
  
  export default {
    name: 'AppIcon',
    props: {
      name: String,
    },
    mounted() {
        this.$nextTick(() => {
            Icon.initFeather();
        });
    },
    methods: {

    },

  };
  </script>
  