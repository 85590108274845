// Import only the necessary Vue functionality
import { createApp } from 'vue';
import App from './App.vue';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './scss/main.scss';
import AppIcon from './components/AppIcon.vue'; 

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import AppModal from './components/Modal';
import 'normalize.css';

import { autoAnimatePlugin } from '@formkit/auto-animate/vue'




const app = createApp(App);

library.add(fas, far, fab);

app.component('font-awesome-icon', FontAwesomeIcon);
app.use(autoAnimatePlugin);



app.component('AppIcon', AppIcon);
app.component('AppModal', AppModal);



// Mount the app to the DOM
app.mount('#app');
