<template>
    <div class="w-100">
        <h5>Кластерний комплекс H-HA/L-HA з гіалуронової кислоти двох молекулярних мас.</h5>
        <p>Концентрація ГК 45 мг на 3 мл препарату.</p>
        <p><strong>ГК Низької молекулярної маси 100 kDA</strong></p>
        <p><strong>ГК Високої молекулярної маси 1400 kDA</strong></p>
        
        
        <h5></h5>
        <p></p>
    </div>
</template>