<template>
    <div class="footer py-5 px-primary">
        <div class="row py-3">
            <div class="col-12 col-lg-6 d-flex align-items-center justify-content-center justify-content-lg-start mb-3 mb-lg-0">
                <span class="me-2">© {{ text.currentYear }}.</span>
                <span class="me-2 d-none d-lg-block">{{ text.copywrite }}</span>
                <strong><a href="/" target="_blank">CITRUM</a></strong>
            </div>
            <div class="col-12 col-lg-6 d-flex align-items-center justify-content-center justify-content-lg-end">
                <a href="/" class="soc-circle d-flex align-items-center justify-content-center me-3" target="_blank">
                    <font-awesome-icon class="fa-lg" :icon="['fab', 'facebook-f']" />
                </a>
                <a href="/" class="soc-circle d-flex align-items-center justify-content-center me-3" target="_blank">
                    <font-awesome-icon class="fa-lg" :icon="['fab', 'instagram']" />
                </a>
                <a href="/" class="soc-circle d-flex align-items-center justify-content-center me-3" target="_blank">
                    <font-awesome-icon class="fa-lg" :icon="['far', 'envelope']" />
                </a>
            </div>
        </div>
    </div>
</template>
<script>


export default {
  props: {
    text: String,
  },
  mounted() {

  },
  methods: {

  },

};
</script>