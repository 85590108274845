<template>
  <div class="container-slide">
    <div class="row section-bg-second">
      <div class="col-12 px-primary py-5">
        <div class="row">
          <div class="col-12 col-lg-6 d-none d-lg-flex align-items-end flex-column">
            <div class="row w-100 mt-pr">
              <div class="col-5 flex-column mt-5">
                <div class="d-flex justify-content-end mb-3">
                  <button type="button" class="btn btn-outline-second rounded-pill text-nowrap font-size-90"
                    data-bs-toggle="modal" data-bs-target="#exampleModal"
                    @click.prevent="openModal('ContentType51', 'ТСА - 11% рН 0,8 / 19% рН 0,7', 'modal-lg')">
                    <span class="mx-2">ТСА - 11% рН 0,8 / 19% рН 0,7</span>
                    <font-awesome-icon :icon="['fas', 'chevron-right']" />
                  </button>
                </div>
                <div class="d-flex justify-content-end mb-3">
                  <button type="button" class="btn btn-outline-second rounded-pill text-nowrap font-size-90"
                    data-bs-toggle="modal" data-bs-target="#exampleModal"
                    @click.prevent="openModal('ContentType52', 'Яблучна і молочна кислота', 'modal-lg')">
                    <span class="mx-2">Яблучна і молочна кислота</span>
                    <font-awesome-icon :icon="['fas', 'chevron-right']" />
                  </button>
                </div>
                <div class="d-flex justify-content-end mb-3">
                  <button type="button" class="btn btn-outline-second rounded-pill text-nowrap font-size-90"
                    data-bs-toggle="modal" data-bs-target="#exampleModal"
                    @click.prevent="openModal('ContentType53', 'Саліцилова кислота', 'modal-lg')">
                    <span class="mx-2">Саліцилова кислота</span>
                    <font-awesome-icon :icon="['fas', 'chevron-right']" />
                  </button>
                </div>
                <div class="d-flex justify-content-end mb-3">
                  <button type="button" class="btn btn-outline-second rounded-pill text-nowrap font-size-90"
                    data-bs-toggle="modal" data-bs-target="#exampleModal"
                    @click.prevent="openModal('ContentType54', 'Гліколева кислота', 'modal-lg')">
                    <span class="mx-2">Гліколева кислота</span>
                    <font-awesome-icon :icon="['fas', 'chevron-right']" />
                  </button>
                </div>
              </div>
              <div class="col-2 px-0">
                <div class="row bg-pr-2 px-2 py-5">
                  <div class="col-12">
                    <img class="w-100" src="/img/pr-2.png" />
                  </div>
                </div>
              </div>

              <div class="col-5 flex-column mt-5">
                <div class="d-flex justify-content-start mb-3">
                  <button type="button" class="btn btn-outline-second rounded-pill text-nowrap font-size-90"
                    data-bs-toggle="modal" data-bs-target="#exampleModal"
                    @click.prevent="openModal('ContentType55', 'Пірролідонкарбонова кислота', 'modal-lg')">
                    <font-awesome-icon :icon="['fas', 'chevron-left']" />
                    <span class="mx-2">Пірролідонкарбонова кислота</span>
                  </button>
                </div>
                <div class="d-flex justify-content-start mb-3">
                  <button type="button" class="btn btn-outline-second rounded-pill text-nowrap font-size-90"
                    data-bs-toggle="modal" data-bs-target="#exampleModal"
                    @click.prevent="openModal('ContentType56', 'Пальмітол гексапептид 19', 'modal-lg')">
                    <font-awesome-icon :icon="['fas', 'chevron-left']" />
                    <span class="mx-2">Пальмітол гексапептид 19</span>
                  </button>
                </div>
                <div class="d-flex justify-content-start mb-3">
                  <button type="button" class="btn btn-outline-second rounded-pill text-nowrap font-size-90"
                    data-bs-toggle="modal" data-bs-target="#exampleModal"
                    @click.prevent="openModal('ContentType57', 'Пантенол, бетаїн', 'modal-lg')">
                    <font-awesome-icon :icon="['fas', 'chevron-left']" />
                    <span class="mx-2">Пантенол, бетаїн</span>
                  </button>
                </div>
                <div class="d-flex justify-content-start mb-3">
                  <button type="button" class="btn btn-outline-second rounded-pill text-nowrap font-size-90"
                    data-bs-toggle="modal" data-bs-target="#exampleModal"
                    @click.prevent="openModal('ContentType58', 'Екстракт м’яти', 'modal-lg')">
                    <font-awesome-icon :icon="['fas', 'chevron-left']" />
                    <span class="mx-2">Екстракт м’яти</span>
                  </button>
                </div>
              </div>
            </div>
            <div class="row w-100">
              <div class="col-12 d-flex justify-content-center">
                <button type="button" class="btn  rounded-pill second-bg d-flex align-items-center px-2"
                  data-bs-toggle="modal" data-bs-target="#exampleModal"
                  @click.prevent="openModal('ContentTypeVideo5', 'PMP', 'modal-lg')">
                  <div class="button-inside-icon">
                    <font-awesome-icon :icon="['fas', 'play']" />
                  </div>
                  <span class="mx-3 text-uppercase">{{ text.video }}</span>
                </button>
              </div>
            </div>
            <div class="row d-none d-lg-flex w-100 mt-auto">
              <div class="col-12 pr-height-bg phb-1">
                <img src="/img/mint.png" />
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <div class="row">
              <div class="col-12 d-flex justify-content-center flex-column text-start">
                <h2 class="mt-3 display-1 sub-title">PMP</h2>
                <span class="text-uppercase color-second desc-title">БІОРЕВІТАЛІЗУЮЧИЙ МОХІТО ПІЛІНГ</span>
              </div>
            </div>
            <div class="row d-flex d-lg-none mt-4">
              <div class="col-6 px-0">
                <div class="row bg-pr-2 px-2 py-5">
                  <div class="col-12">
                    <img class="w-100" src="/img/pr-2.png" />
                  </div>
                </div>
              </div>
              <div class="col-6 flex-column mt-5">
                <div class="d-flex justify-content-start mb-3">
                  <button type="button" class="btn btn-outline-second rounded-pill text-nowrap font-size-90"
                    data-bs-toggle="modal" data-bs-target="#exampleModal"
                    @click.prevent="openModal('ContentType51', 'ТСА - 11% рН 0,8 / 19% рН 0,7', 'modal-lg')">
                    <font-awesome-icon :icon="['fas', 'chevron-left']" />
                    <span class="mx-2">ТСА - 11% рН 0,8 / 19% рН 0,7</span>
                  </button>
                </div>
                <div class="d-flex justify-content-start mb-3">
                  <button type="button" class="btn btn-outline-second rounded-pill text-nowrap font-size-90"
                    data-bs-toggle="modal" data-bs-target="#exampleModal"
                    @click.prevent="openModal('ContentType55', 'Пірролідонкарбонова кислота', 'modal-lg')">
                    <font-awesome-icon :icon="['fas', 'chevron-left']" />
                    <span class="mx-2">Пірролідонкарбонова кислота</span>
                  </button>
                </div>
                <div class="d-flex justify-content-start mb-3">
                  <button type="button" class="btn btn-outline-second rounded-pill text-nowrap font-size-90"
                    data-bs-toggle="modal" data-bs-target="#exampleModal"
                    @click.prevent="openModal('ContentType52', 'Яблучна і молочна кислота', 'modal-lg')">
                    <font-awesome-icon :icon="['fas', 'chevron-left']" />
                    <span class="mx-2">Яблучна і молочна кислота</span>
                  </button>
                </div>
                <div class="d-flex justify-content-start mb-3">
                  <button type="button" class="btn btn-outline-second rounded-pill text-nowrap font-size-90"
                    data-bs-toggle="modal" data-bs-target="#exampleModal"
                    @click.prevent="openModal('ContentType56', 'Пальмітол гексапептид 19', 'modal-lg')">
                    <font-awesome-icon :icon="['fas', 'chevron-left']" />
                    <span class="mx-2">Пальмітол гексапептид 19</span>
                  </button>
                </div>
                <div class="d-flex justify-content-start mb-3">
                  <button type="button" class="btn btn-outline-second rounded-pill text-nowrap font-size-90"
                    data-bs-toggle="modal" data-bs-target="#exampleModal"
                    @click.prevent="openModal('ContentType53', 'Саліцилова кислота', 'modal-lg')">
                    <font-awesome-icon :icon="['fas', 'chevron-left']" />
                    <span class="mx-2">Саліцилова кислота</span>
                  </button>
                </div>
                <div class="d-flex justify-content-start mb-3">
                  <button type="button" class="btn btn-outline-second rounded-pill text-nowrap font-size-90"
                    data-bs-toggle="modal" data-bs-target="#exampleModal"
                    @click.prevent="openModal('ContentType54', 'Гліколева кислота', 'modal-lg')">
                    <font-awesome-icon :icon="['fas', 'chevron-left']" />
                    <span class="mx-2">Гліколева кислота</span>
                  </button>
                </div>
                <div class="d-flex justify-content-start mb-3">
                  <button type="button" class="btn btn-outline-second rounded-pill text-nowrap font-size-90"
                    data-bs-toggle="modal" data-bs-target="#exampleModal"
                    @click.prevent="openModal('ContentType57', 'Пантенол, бетаїн', 'modal-lg')">
                    <font-awesome-icon :icon="['fas', 'chevron-left']" />
                    <span class="mx-2">Пантенол, бетаїн</span>
                  </button>
                </div>
                <div class="d-flex justify-content-start mb-3">
                  <button type="button" class="btn btn-outline-second rounded-pill text-nowrap font-size-90"
                    data-bs-toggle="modal" data-bs-target="#exampleModal"
                    @click.prevent="openModal('ContentType58', 'Екстракт м’яти', 'modal-lg')">
                    <font-awesome-icon :icon="['fas', 'chevron-left']" />
                    <span class="mx-2">Екстракт м’яти</span>
                  </button>
                </div>

              </div>
              <div class="row w-100">
                <div class="col-12 d-flex justify-content-start">
                  <button type="button" class="btn  rounded-pill second-bg d-flex align-items-center px-2"
                    data-bs-toggle="modal" data-bs-target="#exampleModal"
                    @click.prevent="openModal('ContentTypeVideo5', 'PMP', 'modal-lg')">
                    <div class="button-inside-icon">
                      <font-awesome-icon :icon="['fas', 'play']" />
                    </div>
                    <span class="mx-3 text-uppercase">{{ text.video }}</span>
                  </button>
                </div>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-12">
                <div class="card border-0 shadow-sm">
                  <div class="card-head">
                    <nav>
                      <div class="nav nav-tabs border-bottom border-2" id="nav-tab" role="tablist">
                        <button class="nav-link p-4 border-0 active" id="nav-home-tab-2" data-bs-toggle="tab"
                          data-bs-target="#nav-home-2" type="button" role="tab" aria-controls="nav-home-2"
                          aria-selected="true">Наука</button>
                        <button class="nav-link p-4 border-0" id="nav-profile-tab-2" data-bs-toggle="tab"
                          data-bs-target="#nav-profile-2" type="button" role="tab" aria-controls="nav-profile-2"
                          aria-selected="false">Показання</button>
                        <button class="nav-link p-4 border-0" id="nav-page-tab-2-1" data-bs-toggle="tab"
                          data-bs-target="#nav-page-2-1" type="button" role="tab" aria-controls="nav-page-2-1"
                          aria-selected="false">Переваги</button>
                      </div>
                    </nav>
                  </div>
                  <div class="card-body px-4">
                    <div class="tab-content" id="nav-tabContent">
                      <div class="tab-pane fade show active" id="nav-home-2" role="tabpanel"
                        aria-labelledby="nav-home-tab-2" tabindex="0">
                        <p>Під час створення пілінгу PMP використовувалися наукові розробки і дослідження 7 італійських
                          учених і лікарів-експертів естетичної медицини A. Scarano, V. Amodeo, R. Amore, D. Amuso, V.
                          Leonardi, A. Sbarbati, D. Pagnini з університетів Риму, Верони, К’єті-Пескара. </p>
                      </div>
                      <div class="tab-pane fade" id="nav-profile-2" role="tabpanel" aria-labelledby="nav-profile-tab-2"
                        tabindex="0">
                        <p>Вікові зміни шкіри обличчя та тіла (в'яла шкіра, тонка шкіра, зморшки), гіперпігментація
                          різної етіології, акне, постакне, стрії, фолікулярний кератоз.</p>
                      </div>
                      <div class="tab-pane fade" id="nav-page-2-1" role="tabpanel" aria-labelledby="nav-page-tab-2-1"
                        tabindex="0">
                        <ul>
                          <li>Мультивекторна дія: глибоке зволоження, кислотна стимуляція, ботокс-ефект.</li>
                          <li>Комфортна і швидка процедура для пацієнта, має приємний ефект охолодження за рахунок
                            ментолу.</li>
                          <li>Миттєвий видимий результат.</li>
                          <li>Можна застосовувати на молодій/зрілій шкірі.</li>
                          <li>Всесезонна процедура, відсутність періоду реабілітації.</li>
                          <li>Низька собівартість, економна витрата.</li>
                          <li>Легко поєднується з іншими процедурами.</li>
                          <li>Не містить токсичних інгредієнтів. Має сертифікат СE medical device.</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-12 mt-4">
                <div class="row">
                  <div class="col-3 col-lg-1 pe-0">
                    <img class="w-100" src="/img/pr-2.png" />
                  </div>
                  <div class="col-8 col-lg-10 border-start border-black border-2 ms-3 ps-3">
                    <h5>PMP MILD TCA 11%, рН 0,8</h5>
                    <p>Біоревіталізуючий пілінг для всіх типів шкіри, особливо чутливої, шкіра IV-VI фототипів. Для РМР
                      терапії інтимних зон.</p>
                  </div>
                </div>
              </div>
              <div class="col-12 mt-4">
                <div class="row">
                  <div class="col-3 col-lg-1 pe-0">
                    <img class="w-100" src="/img/pr-2.png" />
                  </div>
                  <div class="col-8 col-lg-10 border-start border-black border-2 ms-3 ps-3">
                    <h5>PMP INTENSE TCA 19%, рН 0,7</h5>
                    <p>Біоревіталізуючий пілінг із вмістом активного PMP комплексу у вищій концентрації. Для всіх типів
                      шкіри, особливо щільної, а також у протоколах роботи з тілом.</p>
                  </div>
                </div>
              </div>
              <div class="col-12 mt-4 d-none d-lg-flex justify-content-start justify-content-lg-end">
                <button type="button" class="btn  rounded-pill main-bg d-flex align-items-center px-2"
                  @click="openSideSection">
                  <div class="button-inside-icon">
                    <font-awesome-icon :icon="['fas', 'chevron-left']" />
                  </div>
                  <span class="mx-3 text-uppercase">ЗОНИ ЗАСТОСУВАННЯ</span>

                </button>
              </div>
              <div class="col-12 mt-4 d-flex d-lg-none justify-content-start justify-content-lg-end" v-if="!isSideSectionOpenMobile">
                <button type="button" class="btn  rounded-pill main-bg d-flex align-items-center px-2"
                  @click="openSideSectionMobile">
                  <div class="button-inside-icon">
                    <font-awesome-icon :icon="['fas', 'chevron-down']" />
                  </div>
                  <span class="mx-3 text-uppercase">ЗОНИ ЗАСТОСУВАННЯ</span>

                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="side-section section-bg-second"
      :class="{ 'slide-in': isSideSectionOpen, 'slide-out': !isSideSectionOpen, 'slide-in-mobile': isSideSectionOpenMobile, 'slide-out-mobile': !isSideSectionOpenMobile  }">
      <div class="row">
        <div class="col-12 px-primary pb-5 py-lg-5">
          <div class="row d-none d-lg-flex">
            <div class="col-12 d-flex justify-content-center flex-column text-start">
              <h2 class="mt-3 display-1 sub-title">PMP</h2>
              <span class="text-uppercase color-second desc-title">БІОРЕВІТАЛІЗУЮЧИЙ МОХІТО ПІЛІНГ</span>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-lg-6">
              <div class="row">
                <div class="col-12 mt-4">
                  <div class="card border-0 shadow-sm position-relative">
                    <div class="position-absolute number-plate">01</div>
                    <div class="card-body px-4 pt-4">
                      <h5 class="mb-3"><strong>ДОЗУВАННЯ ПІЛІНГУ РМР І КУРС</strong></h5>
                      <p>Курс складається з 4-6 процедур з інтервалом 1-2 тижні залежно від стану шкіри та мети
                        лікування. Використовувати 1,5-2 мл препарату для однієї із зон: обличчя, шия, декольте, кисті
                        рук.
                      </p>
                      <p>У протоколах роботи по тілу застосовувати РМР INTENSE у дозуванні 1 мл на 10 кв.см.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 mt-4">
                  <div class="card border-0 shadow-sm">
                    <div class="card-body px-4 pt-4">
                      <h5 class="mb-3"><strong>Комбіноване застосування з іншими процедурами</strong></h5>
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col">Процедура</th>
                            <th scope="col">Який РМР обрати?</th>
                            <th scope="col">Коли застосовувати?</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th scope="row">Альгінатні маски, мезоконцентрати</th>
                            <td>MILD, INTENSE </td>
                            <td>До</td>
                          </tr>
                          <tr>
                            <th scope="row">Фракційний мікронідлінг</th>
                            <td>MILD</td>
                            <td>До або разом</td>
                          </tr>
                          <tr>
                            <th scope="row">Ін’єкційні процедури</th>
                            <td>MILD, INTENSE </td>
                            <td>До</td>
                          </tr>
                          <tr>
                            <th scope="row">Фото-, лазеротерапія</th>
                            <td>MILD</td>
                            <td>Після</td>
                          </tr>
                          <tr>
                            <th scope="row">Радіохвильовий ліфтинг</th>
                            <td>MILD</td>
                            <td>Після</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-6 tab-images mt-4">
              <div class="row">
                <div class="col-8 col-lg-9 ps-lg-5">
                  <div class="tab-content" id="v-pills-tabContent">
                    <div class="tab-pane fade show active" id="v-pills-home" role="tabpanel"
                      aria-labelledby="v-pills-home-tab" tabindex="0">
                      <img class="w-100" src="/img/bf-af-1.png" />
                    </div>
                    <div class="tab-pane fade" id="v-pills-profile" role="tabpanel"
                      aria-labelledby="v-pills-profile-tab" tabindex="0">
                      <video style="width:100%" src="/video/pmp-v-1.mp4" controls></video>
                    </div>
                    <div class="tab-pane fade" id="v-pills-page" role="tabpanel" aria-labelledby="v-pills-page-tab"
                      tabindex="0">
                      <video style="width:100%" src="/video/pmp-v-2.mp4" controls></video>
                    </div>
                  </div>
                </div>
                <div class="col-4 col-lg-3">
                  <div class="d-flex align-items-start">
                    <div class="nav flex-column" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                      <button class="nav-link active pt-0" id="v-pills-home-tab" data-bs-toggle="pill"
                        data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home"
                        aria-selected="true">
                        <img class="w-100" src="/img/bf-af-1.png" />
                      </button>
                      <button class="nav-link" id="v-pills-profile-tab" data-bs-toggle="pill"
                        data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile"
                        aria-selected="false">
                        <img class="w-100" src="/img/pmp-v-1.png" />
                      </button>
                      <button class="nav-link" id="v-pills-page-tab" data-bs-toggle="pill"
                        data-bs-target="#v-pills-page" type="button" role="tab" aria-controls="v-pills-page"
                        aria-selected="false">
                        <img class="w-100" src="/img/pmp-v-2.png" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 mt-4 d-flex d-lg-none justify-content-start justify-content-lg-end">
                <button type="button" class="btn  rounded-pill main-bg d-flex align-items-center px-2"
                  @click="openSideSectionMobile">
                  <div class="button-inside-icon">
                    <font-awesome-icon  :icon="['fas', 'chevron-up']" />
                  </div>
                  <span class="mx-3 text-uppercase">СХОВАТИ</span>

                </button>
              </div>
          </div>
          <div class="row mt-4 d-none d-lg-flex align-items-end">
            <div class="col-12 col-lg-6 d-flex justify-content-start">
              <button type="button" class="btn  rounded-pill main-bg d-flex align-items-center px-2"
                @click="closeSideSection">
                <span class="mx-3 text-uppercase">Повернутись</span>
                <div class="button-inside-icon">
                  <font-awesome-icon :icon="['fas', 'chevron-right']" />
                </div>
              </button>
            </div>
            <div class="col-12 col-lg-6 d-none justify-content-end text-end">
              <div class="row w-100">
                <div class="col-12 pr-height-bg phb-2">
                  <img src="/img/mint.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  props: {
    text: {
      type: String,
    },
  },
  data() {
    return {
      isSideSectionOpen: false,
      isSideSectionOpenMobile: false,
    }
  },
  mounted() {

  },
  computed: {

  },
  methods: {
    openModal(type, title, size) {
      this.$emit('open-modal', type, title, size);
    },

    openSideSection() {
      this.isSideSectionOpen = true;
      document.body.classList.add('open-side-section');
    },
    openSideSectionMobile() {
      if(this.isSideSectionOpenMobile){
        this.isSideSectionOpenMobile = false;
        document.body.classList.remove('open-side-section-mobile');
      }else{
        this.isSideSectionOpenMobile = true;
        document.body.classList.add('open-side-section-mobile');
      }
      
    },
    closeSideSection() {
      this.isSideSectionOpen = false;
      document.body.classList.remove('open-side-section');
    }
  },

};
</script>
<style>

</style>